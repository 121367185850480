import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useLocale} from '@/hooks/useLocale';
import {useStore} from '@/store';
import {CheckCircleIcon} from '@heroicons/react/20/solid';
import {XCircleIcon} from '@heroicons/react/20/solid';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {GetCheckoutV2Output} from '@zentact/api/src/trpc/routers/checkoutRouter';
import {
  ErrorCode,
  INVOICE_ID_ATTRIBUTE,
  calculateAmountWithSurcharge,
  formatAmount,
  formatPhone,
  fromMinorUnits,
  isFormattedTrpcError,
} from '@zentact/common';

import {SubmitPaymentResponse} from '@zentact/core';
import {
  Button,
  InputCheckbox,
  InputText,
  Label,
  Loading,
  Typography,
  ValidationError,
  applyTheme,
  cn,
  useNotification,
  useToggleWithData,
} from '@zentact/ui-tailwind';
import {useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {CheckoutOrderDetailsSection} from '../checkout-order-details-section';
import {useAdyenAch} from './adyen-components/use-adyen-ach';
import {useAdyenCustomCard} from './adyen-components/use-adyen-custom-card';
import {ApplePayButton} from './apple-pay/apple-pay-button';
import {CheckoutV2FormData, checkoutV2FormSchema} from './checkout-schema';
import {AchPaymentMethodForm} from './payment-method-forms/ach-payment-method-form';
import {SavedMethodsForm} from './payment-method-forms/saved-methods-form';
import {SchemePaymentMethodForm} from './payment-method-forms/scheme-payment-method-form';
import {PaymentMethodSelector} from './payment-method-selector';

export const CheckoutV2Page = () => {
  const {tenant} = useStore();
  const navigate = useNavigate();
  const {locale} = useLocale();
  const {initiateAdyenCustomCardComponent, customCardComponent} = useAdyenCustomCard();
  const {achComponent, initiateAdyenAchComponent} = useAdyenAch();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const {
    data: iFrameErrorMessage,
    on: openIFrameErrorMessage,
    off: closeIFrameErrorMessage,
  } = useToggleWithData<{message: string; title: string}>();
  const {showErrorNotification} = useNotification();

  const {data: checkout, refetch} = trpc.checkout.getCheckoutInfoV2.useQuery(undefined, {
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    onError: error => {
      if (isSubmitted) {
        // ignore if the payment was successful
        return;
      }
      const errorCode = isFormattedTrpcError(error)
        ? error.data.errorCode
        : ErrorCode.ERROR_GENERIC;

      navigate(RoutePath.ERROR, {
        state: isFormattedTrpcError(error) ? error.data : {errorCode},
      });
    },
    enabled: !isSubmitted,
  });

  const paymentWithSavedMethodMutation = trpc.payment.paymentWithSavedMethod.useMutation();

  useEffect(() => {
    if (!checkout) {
      return;
    }
    applyTheme({
      primary:
        checkout.savePaymentMethod === 'ADD_EMBED' && tenant
          ? tenant.brandConfiguration.primaryColorHex
          : checkout.brandingConfiguration.primaryColorHex,
    });
  }, [checkout, tenant]);

  const showTemporaryErrorMessage = (message: string, title: string) => {
    showErrorNotification(title, message);
    // for future dropin component
    // component.setStatus('error');
    // setTimeout(() => {
    //   component.setStatus('ready');
    // }, 3500);
  };

  const form = useForm<CheckoutV2FormData>({
    resolver: zodResolver(checkoutV2FormSchema()),
    defaultValues: {
      paymentMethod: {type: 'scheme', ach: {}, scheme: {}},
    },
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: {errors},
  } = form;

  const [savedPaymentMethodPublicFacingId, selectedPaymentMethodType, emailReceiptTo] = watch([
    'paymentMethod.savedPaymentMethod.publicFacingId',
    'paymentMethod.type',
    'emailReceiptTo',
  ]);

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  useEffect(() => {
    const email = checkout?.emailReceiptTo || checkout?.shopperEmail;
    if (email) {
      setValue('emailReceiptTo', email);
    }

    // Handle payment method type switching
    if (checkout?.savedPaymentMethods?.length && !initialLoadComplete) {
      setValue('paymentMethod.type', 'savedPaymentMethod');
      setInitialLoadComplete(true);
    }

    if (
      checkout?.savedPaymentMethods?.length &&
      !checkout.savedPaymentMethods
        .map(method => method.publicFacingId)
        .includes(savedPaymentMethodPublicFacingId)
    ) {
      const firstSavedMethod = checkout.savedPaymentMethods[0];

      if (firstSavedMethod) {
        setValue(
          'paymentMethod.savedPaymentMethod.publicFacingId',
          firstSavedMethod.publicFacingId
        );
      }
    }

    // When all saved payment methods are removed, switch to scheme
    if (
      checkout &&
      !checkout.savedPaymentMethods?.length &&
      selectedPaymentMethodType === 'savedPaymentMethod'
    ) {
      setValue('paymentMethod.type', 'scheme');
    }
  }, [
    checkout?.emailReceiptTo,
    checkout?.shopperEmail,
    checkout?.savedPaymentMethods,
    selectedPaymentMethodType,
    savedPaymentMethodPublicFacingId,
    initialLoadComplete,
  ]);

  const showStorePaymentMethodCheckbox =
    checkout?.savePaymentMethod === 'OPTIONAL' &&
    selectedPaymentMethodType === 'scheme' &&
    checkout.merchantShopperId;

  const submitPayment = trpc.checkout.submitPayment.useMutation();
  const updateCheckoutShopperPendingPaymentMutation =
    trpc.checkout.updateCheckoutShopperPendingPayment.useMutation();

  const handleSubmitPaymentResponse = async (
    response: SubmitPaymentResponse,
    checkout: GetCheckoutV2Output
  ) => {
    const isSuccess = response.resultCode === 'Authorised' || response.resultCode === 'Received';

    if (!response.resultCode) {
      // todo
      return;
    }

    if (isSuccess && checkout.savePaymentMethod === 'ADD_EMBED' && response.returnUrl) {
      window.location.href = response.returnUrl;
      return;
    }

    if (isSuccess && checkout.savePaymentMethod === 'ADD_EMBED' && !response.returnUrl) {
      if (checkout.merchantShopperId) {
        await updateCheckoutShopperPendingPaymentMutation.mutateAsync({
          merchantAccountId: checkout.merchantAccountId,
          merchantShopperId: checkout.merchantShopperId,
          pendingPaymentMethod: true,
        });
      }
      window.parent.postMessage('closeIframe', '*');
      return;
    }

    if (isSuccess) {
      setIsSubmitted(true);
      if (response.returnUrl) {
        setTimeout(() => {
          // biome-ignore lint/style/noNonNullAssertion: checked in the enclosing if statement
          window.location.href = response.returnUrl!;
        }, 2500);
      }

      return;
    }

    if (response.resultCode === 'Refused') {
      if (checkout.savePaymentMethod === 'ADD_EMBED') {
        openIFrameErrorMessage({
          message: t`Please validate your payment information or try a different payment method.`,
          title: t`Payment Refused`,
        });
        return;
      }
      showTemporaryErrorMessage(
        t`Please validate your payment information or try a different payment method.`,
        t`Payment Refused`
      );
      return;
    }

    if (response.resultCode === 'Error') {
      if (checkout.savePaymentMethod === 'ADD_EMBED') {
        openIFrameErrorMessage({
          message: t`Please try again. You might need to contact your bank or try a different payment method.`,
          title: t`Payment Error`,
        });
        return;
      }
      showTemporaryErrorMessage(
        t`Please try again. You might need to contact your bank or try a different payment method.`,
        t`Payment Error`
      );
      return;
    }

    if (response.resultCode === 'Cancelled') {
      if (checkout.savePaymentMethod === 'ADD_EMBED') {
        openIFrameErrorMessage({
          message: t`Please verify your payment information and address details, or try an alternative payment method.`,
          title: t`Payment Declined`,
        });
        return;
      }
      showTemporaryErrorMessage(
        t`Please verify your payment information and address details, or try an alternative payment method.`,
        t`Payment Declined`
      );
      return;
    }
  };

  const handleSubmitError = (error: unknown) => {
    if (error instanceof Error) {
      showTemporaryErrorMessage(t`${error.message}`, t`Payment Error`);
    } else {
      showTemporaryErrorMessage(t`An unknown error has occurred`, t`Payment Error`);
    }
  };

  const handleSchemeSubmit = async (data: CheckoutV2FormData) => {
    if (!customCardComponent || !checkout) {
      return;
    }

    if (data.paymentMethod.type !== 'scheme') {
      throw new Error('Invalid payment method.');
    }

    if (!customCardComponent.isValid) {
      return customCardComponent.showValidation();
    }

    const {holderName, fullBillingAddress, partialBillingAddress} = data.paymentMethod.scheme;

    const paymentMethod = {
      type: data.paymentMethod.type,
      holderName,
      ...customCardComponent.state.data,
    };

    const billingAddress =
      checkout.billingAddressMode === 'FULL'
        ? fullBillingAddress
        : checkout.billingAddressMode === 'PARTIAL' && partialBillingAddress
          ? {
              stateOrProvince: 'N/A',
              ...partialBillingAddress,
            }
          : undefined;

    try {
      setSubmitting(true);
      const response = await submitPayment.mutateAsync({
        // adyenSessionId: checkout.id, todo add for dropin only
        emailReceiptTo: data.emailReceiptTo,
        paymentMethod,
        ...(billingAddress && {billingAddress}),
        storePaymentMethod:
          (showStorePaymentMethodCheckbox && data.storePaymentMethod) ||
          checkout.savePaymentMethod === 'ADD_EMBED',
        locale,
      });
      await handleSubmitPaymentResponse(response, checkout);
      return;
    } catch (err) {
      return handleSubmitError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const handleAchSubmit = async (data: CheckoutV2FormData) => {
    if (!achComponent || !checkout) {
      return;
    }

    if (data.paymentMethod.type !== 'ach') {
      throw new Error('Invalid payment method.');
    }

    if (!achComponent.isValid) {
      return achComponent.showValidation();
    }

    const paymentMethod = {
      ...achComponent.state.data,
      ownerName: achComponent.state.data.holderName,
      type: data.paymentMethod.type,
    };

    try {
      setSubmitting(true);
      const response = await submitPayment.mutateAsync({
        // adyenSessionId: checkout.id, todo add for dropin only
        emailReceiptTo: data.emailReceiptTo,
        paymentMethod,
        storePaymentMethod:
          (showStorePaymentMethodCheckbox && data.storePaymentMethod) ||
          checkout.savePaymentMethod === 'ADD_EMBED',
        locale,
      });
      await handleSubmitPaymentResponse(response, checkout);
      return;
    } catch (err) {
      return handleSubmitError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSavedPaymentMethodSubmit = async (data: CheckoutV2FormData) => {
    if (!checkout || !data.paymentMethod.savedPaymentMethod) {
      console.error('Missing checkout or saved payment method');
      return;
    }

    try {
      setSubmitting(true);
      const response = await paymentWithSavedMethodMutation.mutateAsync({
        savedMethodPublicId: data.paymentMethod.savedPaymentMethod.publicFacingId,
        emailReceiptTo: data.emailReceiptTo,
        locale,
      });
      await handleSubmitPaymentResponse(response, checkout);
      return;
    } catch (err) {
      return handleSubmitError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const onSubmit = async (data: CheckoutV2FormData) => {
    if (!checkout) {
      return;
    }
    if (iFrameErrorMessage) {
      closeIFrameErrorMessage();
    }
    switch (data.paymentMethod.type) {
      case 'scheme':
        return handleSchemeSubmit(data);
      case 'ach':
        return handleAchSubmit(data);
      case 'savedPaymentMethod':
        return handleSavedPaymentMethodSubmit(data);
      default:
        throw new Error(`Unsupported payment method type ${selectedPaymentMethodType}`);
    }
  };

  const invoiceNumber = checkout?.checkoutAttributes.find(
    attribute => attribute.name === INVOICE_ID_ATTRIBUTE
  )?.value;

  const phoneNumber = checkout?.brandingConfiguration.phoneNumber;
  const supportEmail = checkout?.brandingConfiguration.supportEmail;

  const supportSection = useMemo(() => {
    if ((!phoneNumber && !supportEmail) || checkout?.savePaymentMethod === 'ADD_EMBED') {
      return null;
    }
    return (
      <p className="mx-4 md:mx-auto md:w-full md:max-w-[480px] text-sm text-center md:text-white text-gray-500 md:mt-10">
        <Trans>For support, please contact</Trans>{' '}
        {phoneNumber && (
          <>
            <a
              href={`tel:${phoneNumber}`}
              className="font-semibold leading-6 whitespace-nowrap text-primary-600 hover:text-primary-500 md:hover:text-primary-100 md:text-primary-200"
              aria-label={`Call ${formatPhone(phoneNumber)}`}
            >
              {formatPhone(phoneNumber)}
            </a>{' '}
            {phoneNumber && supportEmail && <Trans>or</Trans>}{' '}
          </>
        )}
        {supportEmail && (
          <a
            href={`mailto:${supportEmail}`}
            className="font-semibold leading-6 whitespace-nowrap text-primary-600 hover:text-primary-500 md:hover:text-primary-100 md:text-primary-200"
            aria-label={`Email ${supportEmail}`}
          >
            {supportEmail}
          </a>
        )}
        .
      </p>
    );
  }, [supportEmail, phoneNumber, checkout?.savePaymentMethod]);

  const {authorizedAmount, surchargeAmount, surchargeConfiguration} = useMemo(() => {
    const surchargeConfiguration =
      checkout?.surchargeConfiguration &&
      Object.keys(checkout.surchargeConfiguration).includes(
        selectedPaymentMethodType === 'savedPaymentMethod' ||
          selectedPaymentMethodType === 'applepay'
          ? 'scheme'
          : selectedPaymentMethodType
      )
        ? checkout.surchargeConfiguration[
            (selectedPaymentMethodType === 'savedPaymentMethod' ||
            selectedPaymentMethodType === 'applepay'
              ? 'scheme'
              : selectedPaymentMethodType) as keyof typeof checkout.surchargeConfiguration
          ]
        : null;
    if (
      checkout &&
      checkout.amount > 0 &&
      surchargeConfiguration &&
      checkout.checkoutAuthorizationType !== 'PRE_AUTH_CANCELLED'
    ) {
      return calculateAmountWithSurcharge(checkout.amount, surchargeConfiguration);
    }
    return {
      authorizedAmount: checkout?.amount,
      surchargeAmount: 0,
      surchargeConfiguration,
    };
  }, [selectedPaymentMethodType, checkout]);

  const detailsText = useMemo(() => {
    switch (selectedPaymentMethodType) {
      case 'ach':
        return <Trans>ACH Details</Trans>;
      case 'scheme':
        return <Trans>Credit Card Details</Trans>;
      case 'savedPaymentMethod':
        return <Trans>Saved Payment Methods</Trans>;
      case 'applepay':
        return '';
      default:
        return <Trans>Details</Trans>;
    }
  }, [selectedPaymentMethodType]);

  const isOrderDetailsShown = !!checkout?.orderDetails || !!invoiceNumber;
  const surchargePaymentLabel = useMemo(() => {
    if (isOrderDetailsShown || !surchargeConfiguration || !surchargeAmount || !checkout) {
      return null;
    }
    const getSurchargeString = () => {
      if (surchargeConfiguration.percentageFee > 0 && surchargeConfiguration.fixedFee > 0) {
        return `(${surchargeConfiguration.percentageFee}% + ${formatAmount(
          surchargeConfiguration.fixedFee,
          locale,
          checkout.currency
        )})`;
      }
      if (surchargeConfiguration.percentageFee > 0) {
        return `(${surchargeConfiguration.percentageFee}%)`;
      }
      return null;
    };
    return (
      <div className="mb-4 font-medium">
        <Typography variant="header-form-section">
          <div className="text-primary-600">
            <Trans>Payment Surcharge</Trans>
          </div>
        </Typography>
        <div className="text-sm font-medium leading-6 text-gray-900">
          <Trans>Fee</Trans> {getSurchargeString()} -{' '}
          {formatAmount(surchargeAmount, locale, checkout.currency)}
        </div>
      </div>
    );
  }, [surchargeAmount, isOrderDetailsShown, checkout, surchargeConfiguration]);

  const payButtontText = useMemo(() => {
    if (!checkout) {
      return null;
    }
    if (checkout?.savePaymentMethod !== 'ADD_EMBED') {
      return <Trans>Pay {formatAmount(authorizedAmount, locale, checkout.currency)}</Trans>;
    }
    if (checkout.checkoutAuthorizationType === 'PRE_AUTH_CANCELLED') {
      return (
        <Trans>
          Save & Preauthorize for {formatAmount(authorizedAmount, locale, checkout.currency)}
        </Trans>
      );
    }
    return <Trans>Add Payment Method</Trans>;
  }, [checkout, authorizedAmount]);

  if (!checkout) {
    return <Loading />;
  }

  if (isSubmitted) {
    return (
      <div className="flex flex-col items-center justify-center flex-1 min-h-screen pt-0 pb-8 md:px-6 md:pt-12 md:bg-primary-500">
        <div className="md:p-7 p-4 bg-white md:shadow md:mx-auto md:w-full md:max-w-[480px] md:rounded-[20px] text-center">
          <div className="flex justify-center mb-4">
            <CheckCircleIcon height="150" className="text-primary-500" />
          </div>
          <div className="flex justify-center mt-12 text-2xl font-semibold">
            <Trans>Payment Complete!</Trans>
          </div>
          <div className="flex text-sm font-semibold justify-center mt-12 mb-[30px]">
            <Trans>
              You sent {formatAmount(authorizedAmount, locale, checkout.currency)} to{' '}
              {checkout.brandingConfiguration.displayName}.
              {emailReceiptTo ? ` We sent a receipt to ${emailReceiptTo} for your records.` : ''}
            </Trans>
          </div>
        </div>
        {supportSection}
      </div>
    );
  }
  if (iFrameErrorMessage) {
    return (
      <div className="flex flex-col items-center justify-center flex-1 min-h-screen pt-0 pb-8 md:px-6 md:pt-12 md:bg-primary-500">
        <div className="md:p-7 p-4 bg-white md:shadow md:mx-auto md:w-full md:max-w-[480px] md:rounded-[20px] text-center">
          <div className="flex justify-center mb-4">
            <XCircleIcon height="150" className="text-red-400 " aria-hidden="true" />
          </div>
          <div className="flex justify-center mt-12 text-2xl font-semibold">
            {iFrameErrorMessage.title}
          </div>
          <div className="justify-center mt-12 text-sm text-red-700 mb-[30px]">
            {iFrameErrorMessage.message}
          </div>
          <div className="flex justify-center">
            <Button
              size="xl"
              className="w-fit"
              onClick={closeIFrameErrorMessage}
              aria-label="Back"
              variant="secondary"
            >
              <Trans>Try Again</Trans>
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={cn(
        'flex flex-col min-h-screen md:bg-primary-500',
        !checkout?.orderDetails && !invoiceNumber ? 'flex-col' : 'flex-col md:flex-row'
      )}
    >
      {!!checkout && !!authorizedAmount && checkout?.savePaymentMethod !== 'ADD_EMBED' && (
        <CheckoutOrderDetailsSection
          orderDetails={checkout.orderDetails}
          total={authorizedAmount}
          brandConfiguration={checkout.brandingConfiguration}
          invoiceNumber={invoiceNumber}
          currency={checkout.currency}
          surchargeAmount={surchargeAmount}
          surchargeConfiguration={surchargeConfiguration}
        />
      )}
      <div
        className={cn(
          'flex flex-col justify-center flex-1 min-h-full pt-0 pb-8 md:px-6',
          checkout?.savePaymentMethod !== 'ADD_EMBED' && 'md:pt-12'
        )}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="md:p-7 p-4 bg-white md:shadow md:mx-auto md:w-full md:max-w-[480px] md:rounded-[20px] "
        >
          {checkout?.savePaymentMethod !== 'ADD_EMBED' && (
            <div className="mb-4">
              <Typography variant="header-page" className="flex">
                <Trans>Payment Details</Trans>
              </Typography>
              <div className="mt-2">
                <div className="text-sm text-gray-500">{t`Complete your purchase by providing payment details`}</div>
              </div>
              {selectedPaymentMethodType !== 'applepay' && (
                <Label
                  className="mt-4"
                  text={
                    <Typography variant="header-form-section">
                      <div className="text-primary-600">
                        <Trans>Email Receipt To:</Trans>
                      </div>
                    </Typography>
                  }
                >
                  <InputText
                    {...register('emailReceiptTo', {
                      required: true,
                      value: checkout?.emailReceiptTo || checkout?.shopperEmail || '',
                    })}
                    containerClassName="h-10"
                    inputClassName="sm:text-base"
                    placeholder="example@example.com"
                  />
                  <ValidationError isVisible={Boolean(errors.emailReceiptTo?.message)}>
                    {errors.emailReceiptTo?.message}
                  </ValidationError>
                </Label>
              )}
            </div>
          )}
          <PaymentMethodSelector form={form} checkout={checkout} />
          <Typography variant="header-form-section" className="mt-8">
            <div className="text-primary-600">{detailsText}</div>
          </Typography>
          {selectedPaymentMethodType === 'savedPaymentMethod' && checkout.savedPaymentMethods && (
            <SavedMethodsForm
              savedPaymentMethods={checkout.savedPaymentMethods}
              form={form}
              refetch={refetch}
            />
          )}
          {selectedPaymentMethodType === 'scheme' && (
            <SchemePaymentMethodForm
              checkout={checkout}
              form={form}
              initiateAdyenCustomCardComponent={initiateAdyenCustomCardComponent}
            />
          )}
          {selectedPaymentMethodType === 'ach' && (
            <AchPaymentMethodForm
              checkout={checkout}
              initiateAdyenAchComponent={initiateAdyenAchComponent}
            />
          )}
          {showStorePaymentMethodCheckbox && (
            <div className="mt-4">
              <InputCheckbox {...register('storePaymentMethod')} wrapperClassName="items-start">
                <span className="text-sm leading-3">
                  <Trans>I agree to store my payment details and authorize future payments</Trans>
                </span>
              </InputCheckbox>
              <ValidationError isVisible={Boolean(errors.storePaymentMethod)}>
                {errors.storePaymentMethod?.message}
              </ValidationError>
            </div>
          )}
          <div className="mt-8">
            {surchargePaymentLabel}
            {selectedPaymentMethodType !== 'applepay' && checkout && (
              <Button
                type="submit"
                className="h-12 bg-primary-500 hover:bg-primary-600"
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                {payButtontText}
              </Button>
            )}
            {selectedPaymentMethodType === 'applepay' &&
              checkout &&
              checkout.availablePaymentMethods.applepay &&
              window.ApplePaySession &&
              ApplePaySession.canMakePayments() && (
                <ApplePayButton
                  merchantName={
                    checkout.availablePaymentMethods.applepay?.configuration.merchantName
                  }
                  merchantId={checkout.availablePaymentMethods.applepay?.configuration.merchantId}
                  countryCode={checkout.countryCode}
                  currencyCode={checkout.currency}
                  totalLabel={checkout.brandingConfiguration?.displayName}
                  totalAmount={fromMinorUnits(checkout.amount, checkout.currency).toString()}
                  supportedNetworks={checkout.availablePaymentMethods.applepay?.brands ?? []}
                  onError={handleSubmitError}
                  onPaymentSuccess={response => handleSubmitPaymentResponse(response, checkout)}
                  onPaymentError={response => handleSubmitPaymentResponse(response, checkout)}
                >
                  <Trans>Pay</Trans>{' '}
                  {formatAmount(authorizedAmount, checkout.locale, checkout.currency)}{' '}
                  <Trans>with Apple Pay</Trans>
                </ApplePayButton>
              )}
          </div>
        </form>
        {supportSection}
      </div>
    </div>
  );
};
