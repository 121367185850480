import {Button} from '@zentact/ui-tailwind';
import {ReactNode} from 'react';
import {UseApplePaySessionProps, useApplePaySession} from './use-apple-pay-session';

export type ApplePayButtonProps = {
  children?: ReactNode;
} & UseApplePaySessionProps;

export const ApplePayButton = ({
  merchantName,
  merchantId,
  countryCode,
  currencyCode,
  totalLabel,
  totalAmount,
  supportedNetworks,
  onError,
  onPaymentSuccess,
  onPaymentError,
  children,
}: ApplePayButtonProps) => {
  const {isApplePaySupported, startApplePaySession, isSubmitting} = useApplePaySession({
    merchantName,
    merchantId,
    countryCode,
    currencyCode,
    totalLabel,
    totalAmount,
    supportedNetworks,
    onError,
    onPaymentSuccess,
    onPaymentError,
  });

  if (!isApplePaySupported) {
    if (onError) {
      onError(new Error('Apple Pay is not supported on this device'));
    }
    return null;
  }

  return (
    isApplePaySupported && (
      <Button
        className="h-12 bg-primary-500 hover:bg-primary-600"
        isLoading={isSubmitting}
        disabled={isSubmitting}
        onClick={startApplePaySession}
      >
        {children}
      </Button>
    )
  );
};
