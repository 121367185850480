import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useLocale} from '@/hooks/useLocale';
import {useStore} from '@/store';
import '@adyen/adyen-web/dist/adyen.css';
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';
import {Transition} from '@headlessui/react';
import {XCircleIcon} from '@heroicons/react/20/solid';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {ErrorCode, INVOICE_ID_ATTRIBUTE, formatPhone, isFormattedTrpcError} from '@zentact/common';
import {SubmitPaymentResponse} from '@zentact/core';
import {InputText, Label, Loading, ValidationError, useToggleWithData} from '@zentact/ui-tailwind';
import {applyTheme} from '@zentact/ui-tailwind/theme';
import {cn} from '@zentact/ui-tailwind/utils';
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import z from 'zod';
import {CheckoutOrderDetailsSection} from './checkout-order-details-section';
import {useCheckout} from './useCheckout';

export const getContactDetailsFormSchema = () =>
  z.object({
    email: z.string().min(1, t`Email is required`).email(t`Email is not valid`),
  });
export type ContactDetailsFormData = z.infer<ReturnType<typeof getContactDetailsFormSchema>>;

export const CheckoutPage = (): React.ReactElement => {
  const navigate = useNavigate();
  const {locale} = useLocale();
  const [showContactInfoFields, setShowContactInfoFields] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {
    data: iFrameErrorMessage,
    on: openIFrameErrorMessage,
    off: closeIFrameErrorMessage,
  } = useToggleWithData<{message: string; title: string}>();

  const {data: checkout} = trpc.checkout.getCheckoutInfo.useQuery(undefined, {
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    onError: error => {
      if (isSubmitted) {
        // ignore if the payment was successful
        return;
      }
      const errorCode = isFormattedTrpcError(error)
        ? error.data.errorCode
        : ErrorCode.ERROR_GENERIC;

      navigate(RoutePath.ERROR, {state: isFormattedTrpcError(error) ? error.data : {errorCode}});
    },
    enabled: !isSubmitted,
  });

  const form = useForm<ContactDetailsFormData>({
    mode: 'onBlur',
    resolver: zodResolver(getContactDetailsFormSchema()),
  });

  const {
    register,
    getValues,
    trigger,
    formState: {errors},
  } = form;
  const dropinContainer = useRef(null);

  const updateCheckoutEmailReceiptToMutation =
    trpc.checkout.updateCheckoutEmailReceiptTo.useMutation();
  const submitPayment = trpc.checkout.submitPayment.useMutation();
  const updateCheckoutShopperPendingPaymentMutation =
    trpc.checkout.updateCheckoutShopperPendingPayment.useMutation();

  const {tenant} = useStore();

  const {
    isInitializing,
    acknowledgePayment,
    setComponentStatus,
    setTemporaryComponentError,
    showTemporaryErrorMessage,
    sessionDetails,
    getSession,
    initCheckout,
    error,
    hasFatalError,
  } = useCheckout();

  /**
   * Get the session details after authenticated.
   */
  useEffect(() => {
    getSession();
  }, []);

  /**
   * When the session details are ready, initialize the checkout.
   */
  useEffect(() => {
    if (!sessionDetails || !dropinContainer.current || !checkout) {
      return;
    }

    applyTheme({
      primary:
        checkout.savePaymentMethod === 'ADD_EMBED' && tenant
          ? tenant.brandConfiguration.primaryColorHex
          : sessionDetails.brandConfiguration.primaryColorHex,
    });
    // applyTenantFavicon(sessionDetails.brandConfiguration.smallLogoUrl);
    initCheckout(dropinContainer.current, {
      method: 'manual',
      paymentMethodsConfiguration: {
        applepay: {
          // Handling this event with resolve() is required in order for apple pay to work.
          onClick: async (resolve, reject) => {
            await trigger();
            if (!form.formState.isValid) {
              reject();
              return;
            }
            resolve();
          },
          buttonColor: 'black',
          buttonType: 'plain',
          requiredBillingContactFields: ['postalAddress'],
        },
        ...(checkout.savePaymentMethod === 'OPTIONAL' && {
          card: {
            enableStoreDetails: true,
            storePaymentMethod: true,
          },
        }),
      },
      locale,
      ...(checkout.savePaymentMethod === 'ADD_EMBED' ? {removePaymentMethods: ['ach']} : {}),
      ...(checkout.savePaymentMethod === 'ADD_EMBED' &&
      checkout.checkoutAuthorizationType === 'PRE_AUTH_CANCELLED'
        ? {payButtonText: t`Save & Preauthorize for {value}`}
        : {}),
      onSubmit: async (state, component) => {
        if (iFrameErrorMessage) {
          closeIFrameErrorMessage();
        }

        if (checkout.savePaymentMethod !== 'ADD_EMBED') {
          await trigger();
          if (!form.formState.isValid) {
            setComponentStatus('ready', component);
            return;
          }

          updateCheckoutEmailReceiptToMutation.mutate({
            ...getValues(),
          });
        }

        const storePaymentMethodEnabled = state.data.storePaymentMethod;

        let result: SubmitPaymentResponse;
        try {
          result = await submitPayment.mutateAsync({
            adyenSessionId: sessionDetails.id,
            paymentMethod: state.data.paymentMethod,
            billingAddress: state.data.billingAddress,
            storePaymentMethod: storePaymentMethodEnabled,
            locale,
          });
          setIsSubmitted(true);
        } catch (err) {
          if (err instanceof Error) {
            showTemporaryErrorMessage(component, t`${err.message}`, t`Payment Error`);
          } else {
            showTemporaryErrorMessage(
              component,
              t`An unknown error has occurred`,
              t`Payment Error`
            );
          }
          return;
        }
        if (result.resultCode === 'Cancelled') {
          if (checkout.savePaymentMethod === 'ADD_EMBED') {
            openIFrameErrorMessage({
              message: t`Please verify your payment information and address details, or try an alternative payment method.`,
              title: t`Payment Declined`,
            });
            setComponentStatus('ready', component);
            return;
          }
          showTemporaryErrorMessage(
            component,
            t`Please verify your payment information and address details, or try an alternative payment method.`,
            t`Payment Declined`
          );
          return;
        }
        if (result.resultCode === 'Refused') {
          if (checkout.savePaymentMethod === 'ADD_EMBED') {
            openIFrameErrorMessage({
              message: t`Please validate your payment information or try a different payment method.`,
              title: t`Payment Refused`,
            });
            setComponentStatus('ready', component);
            return;
          }
          showTemporaryErrorMessage(
            component,
            t`Please validate your payment information or try a different payment method.`,
            t`Payment Refused`
          );
          return;
        }
        if (result.resultCode === 'Error') {
          if (checkout.savePaymentMethod === 'ADD_EMBED') {
            openIFrameErrorMessage({
              message: t`Please try again. You might need to contact your bank or try a different payment method.`,
              title: t`Payment Error`,
            });
            setComponentStatus('ready', component);
            return;
          }
          showTemporaryErrorMessage(
            component,
            t`Please try again. You might need to contact your bank or try a different payment method.`,
            t`Payment Error`
          );
          return;
        }
        const isSuccess = result.resultCode === 'Authorised' || result.resultCode === 'Received';
        if (isSuccess && checkout.savePaymentMethod === 'ADD_EMBED' && result.returnUrl) {
          window.location.href = result.returnUrl;
          return;
        }

        if (isSuccess && checkout.savePaymentMethod === 'ADD_EMBED' && !result.returnUrl) {
          if (checkout.shopperId) {
            await updateCheckoutShopperPendingPaymentMutation.mutateAsync({
              merchantAccountId: checkout.merchantAccountId,
              merchantShopperId: checkout.shopperId,
              pendingPaymentMethod: true,
            });
          }
          window.parent.postMessage('closeIframe', '*');
          return;
        }

        if (isSuccess) {
          component.setStatus('success');
          setShowContactInfoFields(false);
          acknowledgePayment();
          if (result.returnUrl) {
            setTimeout(() => {
              // biome-ignore lint/style/noNonNullAssertion: checked in the enclosing if statement
              window.location.href = result.returnUrl!;
            }, 2500);
          }

          return;
        }
        console.error("Don't know how to handle resultCode", result.resultCode);

        component.setStatus('ready');
      },
      onError: (_error: unknown, component: DropinElement) => {
        if (component) {
          setTemporaryComponentError(component);
          return;
        }
      },
    });
    return () => {
      if (dropinContainer.current) {
        dropinContainer.current = null;
      }
    };
  }, [sessionDetails, dropinContainer.current, setIsSubmitted, checkout]);

  useEffect(() => {
    if (!hasFatalError && !error) return;
    if (hasFatalError) {
      navigate(RoutePath.ERROR, {state: isFormattedTrpcError(error) ? error.data : error});
    }
  }, [hasFatalError, error]);

  const phoneNumber = sessionDetails?.brandConfiguration.phoneNumber;
  const supportEmail = sessionDetails?.brandConfiguration.supportEmail;

  const invoiceNumber = sessionDetails?.checkout.checkoutAttributes.find(
    attribute => attribute.name === INVOICE_ID_ATTRIBUTE
  )?.value;

  return (
    <>
      {isInitializing && <Loading />}
      <div
        className={cn(
          'flex flex-col min-h-screen',
          !sessionDetails?.checkout.orderDetails && !invoiceNumber
            ? 'flex-col'
            : 'flex-col md:flex-row',
          !isInitializing && 'md:bg-primary-500'
        )}
      >
        {!!sessionDetails && !isInitializing && checkout?.savePaymentMethod !== 'ADD_EMBED' && (
          <CheckoutOrderDetailsSection
            orderDetails={sessionDetails.checkout.orderDetails}
            total={sessionDetails.checkout.amount}
            brandConfiguration={sessionDetails.brandConfiguration}
            invoiceNumber={invoiceNumber}
            currency={sessionDetails.checkout.currency}
          />
        )}
        <div
          className={`flex min-h-full flex-1 flex-col justify-center pb-8 pt-0 md:px-6 md:pt-12 ${
            isInitializing ? 'hidden' : ''
          }`}
        >
          <div className="md:p-7 md:shadow md:mx-auto md:w-full md:max-w-[480px] md:rounded-[20px] p-4 bg-white overflow-auto">
            {!!iFrameErrorMessage && (
              <div className="p-4 rounded-md bg-red-50">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon className="w-5 h-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">{iFrameErrorMessage.title}</h3>
                    <div className="mt-2 text-sm text-red-700">{iFrameErrorMessage.message}</div>
                  </div>
                </div>
              </div>
            )}
            {showContactInfoFields && checkout && checkout.savePaymentMethod !== 'ADD_EMBED' && (
              <Transition
                show={!isInitializing}
                as={Fragment}
                enter="transition-opacity ease-out duration-[600ms] delay-0"
                enterFrom="opacity-0"
                enterTo="opacity-100"
              >
                <div>
                  <div className="p-4 pb-0">
                    <h2 className="text-xl font-semibold text-primary-500">
                      <Trans>Contact Information</Trans>
                    </h2>
                    <Label className="mt-4" text={<Trans>Email Receipt To:</Trans>}>
                      <InputText
                        {...register('email', {
                          required: true,
                          value: checkout?.emailReceiptTo || checkout?.shopperEmail || '',
                        })}
                        containerClassName="h-10"
                        inputClassName="sm:text-base"
                        placeholder="example@example.com"
                      />
                      <ValidationError isVisible={Boolean(errors.email?.message)}>
                        {errors.email?.message}
                      </ValidationError>
                    </Label>
                  </div>
                  <hr className="h-[1px] mx-4 my-6 bg-gray-300 border-0" />
                  <h2 className="mx-4 mb-2 text-xl font-semibold text-primary-500">
                    <Trans>Payment Method</Trans>
                  </h2>
                </div>
              </Transition>
            )}
            <div ref={dropinContainer} />
          </div>

          {(phoneNumber || supportEmail) && checkout?.savePaymentMethod !== 'ADD_EMBED' && (
            <p className="mx-4 text-sm text-center text-gray-500 md:text-white md:mt-1">
              <Trans>For support, please contact</Trans>{' '}
              {phoneNumber && (
                <>
                  <a
                    href={`tel:${phoneNumber}`}
                    className="font-semibold leading-6 whitespace-nowrap text-primary-600 md:text-primary-200 hover:text-primary-500 md:hover:text-primary-100"
                    aria-label={`Call ${formatPhone(phoneNumber)}`}
                  >
                    {formatPhone(phoneNumber)}
                  </a>{' '}
                  {phoneNumber && supportEmail && <Trans>or</Trans>}{' '}
                </>
              )}
              {supportEmail && (
                <a
                  href={`mailto:${supportEmail}`}
                  className="font-semibold leading-6 whitespace-nowrap text-primary-600 hover:text-primary-500 md:text-primary-200 md:hover:text-primary-100"
                  aria-label={`Email ${supportEmail}`}
                >
                  {supportEmail}
                </a>
              )}
              .
            </p>
          )}
        </div>
      </div>
    </>
  );
};
