import {trpc} from '@/api/trpcClient';
import {SubmitPaymentResponse} from '@zentact/core';
import {useCallback, useState} from 'react';

export type UseApplePaySessionProps = {
  merchantName: string;
  merchantId: string;
  countryCode: string;
  currencyCode: string;
  totalLabel: string;
  totalAmount: string;
  supportedNetworks: string[];
  onError?: (error: Error) => void;
  onPaymentSuccess?: (response: SubmitPaymentResponse) => void;
  onPaymentError?: (response: SubmitPaymentResponse) => void;
};
export const useApplePaySession = ({
  merchantName,
  merchantId,
  countryCode,
  currencyCode,
  totalLabel,
  totalAmount,
  supportedNetworks,
  onError = () => {},
  onPaymentSuccess = () => {},
  onPaymentError = () => {},
}: UseApplePaySessionProps) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const createApplePaySession = trpc.checkout.createApplePaySession.useMutation();
  const submitPayment = trpc.checkout.submitPayment.useMutation();

  const isApplePaySupported = window.ApplePaySession && ApplePaySession.canMakePayments();

  const handleError = useCallback(
    (error: unknown) => {
      if (onError) {
        onError(error instanceof Error ? error : new Error('Unknown error', {cause: error}));
      }
      setSubmitting(false);
    },
    [onError]
  );

  const handlePaymentSuccess = useCallback(
    (response: SubmitPaymentResponse) => {
      if (onPaymentSuccess) {
        onPaymentSuccess(response);
      }
      setSubmitting(false);
    },
    [onPaymentSuccess]
  );

  const handlePaymentError = useCallback(
    (response: SubmitPaymentResponse) => {
      if (onPaymentError) {
        onPaymentError(response);
      }
      setSubmitting(false);
    },
    [onPaymentError]
  );

  const startApplePaySession = useCallback(() => {
    const session = new ApplePaySession(14, {
      countryCode,
      currencyCode,
      total: {
        label: totalLabel,
        amount: totalAmount,
      },
      supportedNetworks,
      merchantCapabilities: ['supports3DS'],
      requiredBillingContactFields: ['postalAddress'],
      requiredShippingContactFields: ['email'],
    });

    session.onvalidatemerchant = async event => {
      try {
        if (!(event.target instanceof ApplePaySession)) {
          throw new Error('Invalid ApplePay event target');
        }

        const adyenApplePaySession = await createApplePaySession.mutateAsync({
          displayName: merchantName,
          merchantIdentifier: merchantId,
        });

        // Parse Adyen's ApplePay session data
        const merchantValidationData = JSON.parse(atob(adyenApplePaySession.data));

        event.target.completeMerchantValidation(merchantValidationData);
      } catch (error) {
        handleError(error);
      }
    };

    session.onpaymentauthorized = async event => {
      try {
        if (!(event.target instanceof ApplePaySession)) {
          throw new Error('Invalid ApplePay event target');
        }

        const applePayToken = btoa(JSON.stringify(event.payment.token.paymentData));
        const billingAddress = (() => {
          const billingContact = event.payment.billingContact;
          if (!billingContact) {
            return undefined;
          }

          const addressLines = billingContact.addressLines ?? [];

          return {
            country: billingContact.countryCode ?? '',
            city: billingContact.locality ?? '',
            street: addressLines[0] ?? '',
            postalCode: billingContact.postalCode ?? '',
            stateOrProvince: billingContact.administrativeArea ?? '',
            ...(addressLines[1] && {houseNumberOrName: addressLines[1]}),
          };
        })();

        const response = await submitPayment.mutateAsync({
          emailReceiptTo: event.payment.shippingContact?.emailAddress,
          paymentMethod: {
            type: 'applepay',
            applePayToken,
          },
          billingAddress,
        });

        if (response.resultCode === 'Authorised' || response.resultCode === 'Received') {
          event.target.completePayment(ApplePaySession.STATUS_SUCCESS);
          handlePaymentSuccess(response);
          return;
        }

        event.target.completePayment(ApplePaySession.STATUS_FAILURE);
        handlePaymentError(response);
      } catch (error) {
        handleError(error);
      }
    };

    session.oncancel = _event => {
      setSubmitting(false);
    };

    setSubmitting(true);
    session.begin();
  }, [
    createApplePaySession,
    submitPayment,
    merchantName,
    merchantId,
    countryCode,
    currencyCode,
    totalLabel,
    totalAmount,
    supportedNetworks,
    handleError,
    handlePaymentSuccess,
    handlePaymentError,
  ]);

  return {
    isApplePaySupported,
    startApplePaySession,
    isSubmitting,
  };
};
