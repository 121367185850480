import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {ShopperListItemOutput} from '@zentact/api/src/trpc/routers/savedPaymentMethodRouter';
import {LocaleCode, formatAmount, formatLocaleDate} from '@zentact/common';
import {DateTime} from 'luxon';
import {useCallback, useState} from 'react';
import {DropDownMinimalMenuIcon, renderPaymentMethod, useNotification} from '../../../';
import {FlatPillWithDot} from '../../../elements';
import {DeletePaymentMethodConfirmDialog} from './delete-payment-method-confirm-dialog';

type ShopperSavedPaymentMethodsListProps = {
  shopperRow: ShopperListItemOutput | null;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  refetch: () => void;
  openPreauthorizeDialog: (paymentMethodIdToken: string) => void;
  locale: LocaleCode;
};

type PaymentMethodType = ShopperListItemOutput['savedPaymentMethods'][number];

export const ShopperSavedPaymentMethodsList = ({
  shopperRow,
  trpc,
  refetch,
  openPreauthorizeDialog,
  locale,
}: ShopperSavedPaymentMethodsListProps) => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {i18n} = useLingui();
  const [deleteMethodConfirmationOpen, setDeleteMethodConfirmationOpen] = useState(false);
  const [savedPaymentMethodPublicIdToDelete, setSavedPaymentMethodPublicIdToDelete] = useState<
    string | null
  >(null);

  const handleDeleteMethodClick = useCallback((selectedPaymentMethod: PaymentMethodType) => {
    setSavedPaymentMethodPublicIdToDelete(selectedPaymentMethod.publicFacingId);
    setDeleteMethodConfirmationOpen(true);
  }, []);

  const makeMethodPrimaryMutation =
    trpc.savedPaymentMethod.makeStoredPaymentMethodPrimary.useMutation({
      onError: error => {
        refetch();
        showErrorNotification(error.message);
      },
      onSuccess: async () => {
        showSuccessNotification('Set Payment method to Primary');
        refetch();
      },
    });

  if (!shopperRow) {
    return null;
  }
  const {savedPaymentMethods, primaryPaymentMethod} = shopperRow;

  const activePaymentMethods = primaryPaymentMethod
    ? savedPaymentMethods
        .filter(method => method.status !== 'DISABLED' && method.status !== 'ARCHIVED')
        .sort(
          (a, b) =>
            +(b.publicFacingId === primaryPaymentMethod.publicFacingId) -
            +(a.publicFacingId === primaryPaymentMethod.publicFacingId)
        )
    : [];

  const handleMakeMethodPrimary = (selectedPaymentMethod: PaymentMethodType) => {
    makeMethodPrimaryMutation.mutate({
      storedPaymentMethodPublicId: selectedPaymentMethod.publicFacingId,
    });
  };

  return (
    <>
      <div className="border-gray-100">
        <dl className="">
          <div className="flex items-center pb-3 border-b border-gray-100 sm:grid sm:grid-cols-5 sm:gap-4">
            <div className="mt-1 space-x-2 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">
              {i18n._('Card Details')}
            </div>
            <div className="mt-1 space-x-2 text-sm font-medium leading-6 text-gray-900 sm:col-span-2 max-sm:hidden">
              {i18n._('Last Preauth Status')}
            </div>
          </div>
          {activePaymentMethods.map(paymentMethod => (
            <ActivePaymentMethod
              key={`paymentMethod-${paymentMethod.publicFacingId}`}
              paymentMethod={paymentMethod}
              primaryPaymentMethod={primaryPaymentMethod}
              handleMakeMethodPrimary={handleMakeMethodPrimary}
              handleDeleteMethodClick={handleDeleteMethodClick}
              openPreauthorizeDialog={openPreauthorizeDialog}
              locale={locale}
            />
          ))}
          {shopperRow.pendingPaymentMethod && (
            <div className="py-3 border-b border-gray-100 animate-pulse sm:grid sm:grid-cols-2 sm:gap-4">
              <div className="flex items-center space-x-2">
                <div className="bg-slate-700 h-7 w-11" />
                <div className="flex-1 py-1 space-y-6">
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-2">
                      <div className="h-5 col-span-1 rounded bg-slate-700" />
                      <div className="h-5 rounded bg-slate-700" style={{width: '4.3rem'}} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-gray-700 sm:col-span-1 sm:flex sm:items-center sm:justify-end">
                {i18n._('pending...')}
              </div>
            </div>
          )}
          {!activePaymentMethods.length && !shopperRow.pendingPaymentMethod && (
            <div className="mt-3 text-center">{i18n._('No Payment Methods')}</div>
          )}
        </dl>
      </div>
      <DeletePaymentMethodConfirmDialog
        isOpen={deleteMethodConfirmationOpen}
        onClose={() => setDeleteMethodConfirmationOpen(false)}
        trpc={trpc}
        refetch={refetch}
        publicFacingId={savedPaymentMethodPublicIdToDelete}
      />
    </>
  );
};

type PaymentMethodsListProps = {
  paymentMethod: PaymentMethodType;
  primaryPaymentMethod: {publicFacingId: string} | null;
  handleMakeMethodPrimary: (row: PaymentMethodType) => void;
  handleDeleteMethodClick: (row: PaymentMethodType) => void;
  openPreauthorizeDialog: (paymentMethodIdToken: string) => void;
  locale: LocaleCode;
};
const ActivePaymentMethod = ({
  paymentMethod,
  primaryPaymentMethod,
  handleMakeMethodPrimary,
  handleDeleteMethodClick,
  openPreauthorizeDialog,
  locale,
}: PaymentMethodsListProps) => {
  const {i18n} = useLingui();

  const isPrimaryMethod = paymentMethod.publicFacingId === primaryPaymentMethod?.publicFacingId;
  const options: {name: string; onClick: () => void; itemClassName?: string}[] = [
    ...(!isPrimaryMethod
      ? [{name: i18n._('Make Primary'), onClick: () => handleMakeMethodPrimary(paymentMethod)}]
      : []),
    {
      name: i18n._('Preauthorize'),
      onClick: () => openPreauthorizeDialog(paymentMethod.paymentMethodIdToken),
    },
    {
      name: i18n._('Delete'),
      onClick: () => handleDeleteMethodClick(paymentMethod),
      itemClassName: 'text-red-500',
    },
  ];
  const displayLastPreauthResult = () => {
    if (!paymentMethod.lastPreauthResult) {
      return <span className="italic text-gray-500">{i18n._('No Preauth attempted')}</span>;
    }
    const amount = formatAmount(
      paymentMethod.lastPreauthResult.amount,
      locale,
      paymentMethod.lastPreauthResult.currency
    );
    const date = formatLocaleDate(paymentMethod.lastPreauthResult.date, DateTime.DATE_MED);

    if (!paymentMethod.lastPreauthResult.success) {
      return (
        <span className="text-red-500">
          {i18n._('Failed for {amount} on {date}', {amount, date})}
        </span>
      );
    }
    return <span className="">{i18n._('Succeeded for {amount} on {date}', {amount, date})}</span>;
  };

  return (
    <div className="">
      <div
        className="grid items-center grid-cols-2 gap-4 py-3 border-b border-gray-100 sm:grid-cols-10"
        key={`paymentMethod-${paymentMethod.publicFacingId}`}
      >
        <dt className="flex items-center col-span-1 row-start-1 mt-1 space-x-2 text-sm font-medium leading-6 text-gray-900 sm:col-span-6">
          {renderPaymentMethod(paymentMethod.paymentMethod, paymentMethod.cardLastFour)}
          {paymentMethod.expiryYear &&
          paymentMethod.expiryMonth &&
          new Date(paymentMethod.expiryYear, paymentMethod.expiryMonth, 0) > new Date() ? (
            <div>{`${paymentMethod.expiryMonth.toString().padStart(2, '0')}/${
              paymentMethod.expiryYear
            }`}</div>
          ) : (
            <span className="mr-3 font-semibold text-red-600">Expired</span>
          )}
          {isPrimaryMethod && <FlatPillWithDot color={'green'} label={'Primary'} />}
        </dt>
        <dd className="flex col-span-2 mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0 sm:row-start-1">
          <div>{displayLastPreauthResult()}</div>
        </dd>
        <dd className="flex justify-end col-span-1 row-start-1 mt-1 max-sm:col-start-2">
          <DropDownMinimalMenuIcon items={options} />
        </dd>
      </div>
    </div>
  );
};
