import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {EmailSettingsForm, EmailSettingsFormData} from '@/pages/configure/email-settings-form';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {
  AlertOverlayWithConfirmation,
  BaseTheme,
  BrandColors,
  Breadcrumbs,
  Typography,
  UploadBrandLogo,
  uploadFileToCloudStorage,
  useNotification,
} from '@zentact/ui-tailwind';
import {nanoid} from 'nanoid';
import {useCallback, useState} from 'react';
import {
  SavePaymentMethod,
  SavePaymentMethodData,
  SavePaymentMethodForm,
} from './save-payment-methods-form';
import {
  SupportPhoneNumberSettingsForm,
  SupportPhoneNumberSettingsFormData,
} from './support-phone-number-settings-form';

export enum LogoType {
  SQUARE = 'square',
  HORIZONTAL = 'horizontal',
}

const LOGO_DISPLAY_SETTINGS = {
  horizontal: '?max-h=80&max-w=300&fit=fill',
  square: '?max-h=80&max-w=80&fit=crop',
};

const getBreadCrumbs = () => [{name: t`Configure`, href: RoutePath.CONFIGURE, current: true}];

export const Configure = () => {
  const [deleteConfirm, setDeleteConfirm] = useState<LogoType>();
  const [isLoadingHorizontal, setIsLoadingHorizontal] = useState(false);
  const [isLoadingSquare, setIsLoadingSquare] = useState(false);
  const [isConfirmationResetOpen, setIsConfirmationResetOpen] = useState(false);
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {organizationRefetch} = useStore();

  const trpcContext = trpc.useUtils();
  const {data: organizationBrandData} = trpc.organization.getContextOrganization.useQuery();

  const {
    logoUrl: horizontalUrl,
    logoFileName: horizontalLogoFileName,
    smallLogoUrl: squareUrl,
    smallLogoFileName: squareLogoFileName,
    primaryColorHex: primaryDefaultColor = BaseTheme.primary,
    supportEmail,
    supportPhoneNumber,
  } = organizationBrandData?.brandConfiguration || {};

  const {savePaymentMethod} = organizationBrandData?.organizationConfiguration || {};

  const updateBranding = trpc.organization.updateBranding.useMutation({
    onSuccess: updatedOrganization => {
      setIsConfirmationResetOpen(false);
      setDeleteConfirm(undefined);
      trpcContext.organization.getContextOrganization.setData(undefined, updatedOrganization);
    },
    onError: err => {
      console.error(err);
    },
  });

  const updateSavePaymentMethod =
    trpc.savedPaymentMethod.updateOrganizationSavePaymentMethodOption.useMutation({
      onSuccess: () => {
        organizationRefetch();
        showSuccessNotification(t`Save payment method was successfully updated`);
      },
      onError: err => {
        showErrorNotification(t`Failed to update save payment method`);
        console.error(err);
      },
    });

  const onSelectLogo = useCallback(
    async (file: File, type: LogoType) => {
      if (type === LogoType.SQUARE) {
        setIsLoadingSquare(true);
      } else {
        setIsLoadingHorizontal(true);
      }
      // file with the same name can already be uploaded and cached on the UI. Adds a random part to avoid uploading same name file.
      const fileName = `${nanoid()}-${file.name}`;

      try {
        const {signedUrl} = await trpcContext.organization.generateLogoUploadLink.fetch({
          fileName,
        });

        await uploadFileToCloudStorage(signedUrl, file);

        const updatedOrganization = await trpcContext.client.organization.updateBranding.mutate(
          type === LogoType.SQUARE
            ? {
                smallLogoFileName: fileName,
              }
            : {
                logoFileName: fileName,
              }
        );
        trpcContext.organization.getContextOrganization.setData(undefined, updatedOrganization);
        showSuccessNotification(t`New logo was successfully uploaded`);
      } catch (e) {
        showErrorNotification(t`Failed to upload a logo`);
        console.error(e);
      }

      if (type === LogoType.SQUARE) {
        setIsLoadingSquare(false);
      } else {
        setIsLoadingHorizontal(false);
      }
    },
    [showErrorNotification, showSuccessNotification, setIsLoadingSquare, setIsLoadingHorizontal]
  );

  const onUpdateBrandColors = (color: string) => {
    updateBranding.mutate({primaryColorHex: color});
  };

  const onUpdateEmailSettings = (emailSettingsFormData: EmailSettingsFormData) => {
    return new Promise<void>(resolve => {
      updateBranding.mutate(
        {supportEmail: emailSettingsFormData.supportEmail},
        {
          onSuccess: () => {
            showSuccessNotification(t`Email settings were successfully updated`);
            resolve();
          },
          onError: () => {
            showErrorNotification(t`Failed to update email settings`);
            resolve();
          },
        }
      );
    });
  };

  const onUpdateSupportPhoneNumberSettings = async (
    supportPhoneNumberSettingsFormData: SupportPhoneNumberSettingsFormData
  ) => {
    return new Promise<void>(resolve => {
      updateBranding.mutate(
        {supportPhoneNumber: supportPhoneNumberSettingsFormData.supportPhoneNumber},
        {
          onSuccess: () => {
            showSuccessNotification(t`Support phone number settings were successfully updated`);
            resolve();
          },
          onError: () => {
            showErrorNotification(t`Failed to update support phone number settings`);
            resolve();
          },
        }
      );
    });
  };

  const onUpdateSavePaymentMethod = (savePaymentMethodFormData: SavePaymentMethodData) => {
    updateSavePaymentMethod.mutate({
      savePaymentMethod: savePaymentMethodFormData.savePaymentMethod,
    });
  };

  const onDeleteLogo = (type?: LogoType) => {
    if (type === LogoType.HORIZONTAL) {
      updateBranding.mutate({
        logoFileName: null,
      });
    }
    if (type === LogoType.SQUARE) {
      updateBranding.mutate({
        smallLogoFileName: null,
      });
    }
  };

  const onDeleteConfirm = () => {
    if (deleteConfirm) {
      onDeleteLogo(deleteConfirm);
    }
  };
  const onResetColors = () => {
    setIsConfirmationResetOpen(true);
  };
  const onResetColorConfirm = () => {
    updateBranding.mutate({
      primaryColorHex: BaseTheme.primary,
    });
  };

  return (
    <div>
      <Breadcrumbs pages={getBreadCrumbs()} />
      <Typography variant="header-page" className="pt-4">
        <Trans>Configure</Trans>
      </Typography>
      <div className="mt-4 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow">
        <div className="px-4 py-5 sm:px-6">
          <Typography variant="header-section">
            <Trans>Logo</Trans>
          </Typography>
          <div className="mt-3 text-gray-700">
            <Trans>
              The Horizontal logo will appear on sign in, onboarding, email templates, and in your
              customer's administration portal.
            </Trans>
          </div>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <UploadBrandLogo
            localeText={{
              title: t`Horizontal Logo`,
              delete: t`Delete`,
              upload: t`Click to upload`,
              dragndrop: t`or drag and drop`,
            }}
            logoUrl={
              horizontalUrl ? `${horizontalUrl}${LOGO_DISPLAY_SETTINGS.horizontal}` : undefined
            }
            logoExist={!!horizontalLogoFileName}
            isLoading={isLoadingHorizontal}
            onDeleteLogo={() => setDeleteConfirm(LogoType.HORIZONTAL)}
            onSelectFile={f => onSelectLogo(f, LogoType.HORIZONTAL)}
            emptyLogoClassName="w-60 h-20"
          />
        </div>
        <div className="hidden p-4 sm:px-6">
          <UploadBrandLogo
            isLoading={isLoadingSquare}
            localeText={{
              title: t`Square Logo`,
              delete: t`Delete`,
              upload: t`Click to upload`,
              dragndrop: t`or drag and drop`,
            }}
            logoUrl={squareUrl ? `${squareUrl}${LOGO_DISPLAY_SETTINGS.square}` : undefined}
            logoExist={!!squareLogoFileName}
            onDeleteLogo={() => setDeleteConfirm(LogoType.SQUARE)}
            onSelectFile={f => onSelectLogo(f, LogoType.SQUARE)}
            emptyLogoClassName="w-20 h-20"
            maxImageDimensions={{width: 1024, height: 1024}}
          />
        </div>
        <AlertOverlayWithConfirmation
          setOpen={() => setDeleteConfirm(undefined)}
          handleAction={onDeleteConfirm}
          localeText={{
            title: t`Confirm deletion`,
            description: t`Are you sure you want to delete this logo? Your changes will be lost.`,
            cancel: t`Cancel`,
            confirm: t`Confirm`,
          }}
          open={Boolean(deleteConfirm)}
        />
      </div>
      <div className="mt-4 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow">
        <div className="px-4 py-5 sm:px-6">
          <Typography variant="header-section">
            <Trans>Brand Color</Trans>
          </Typography>
          <div className="mt-3 text-gray-500">
            <Trans>
              Your brand color will be used to create a custom portal and emails for your merchants.
              For best result, pick a mid-range value. The other values are auto generated to create
              a cohesive feel.
            </Trans>
          </div>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <Typography variant="header-subsection">
            <Trans>Base HEX Color Value</Trans>
          </Typography>
          <BrandColors
            primaryDefaultColor={primaryDefaultColor}
            onUpdateColors={onUpdateBrandColors}
            onResetColors={onResetColors}
            localeText={{
              apply: t`Apply Color Value`,
              reset: t`Reset to Default`,
              cancel: t`Cancel`,
            }}
          />
          <AlertOverlayWithConfirmation
            setOpen={setIsConfirmationResetOpen}
            handleAction={onResetColorConfirm}
            localeText={{
              title: t`Confirm reset to Default`,
              description: t`Are you sure you want to reset colors to Default? Your changes will be lost.`,
              cancel: t`Cancel`,
              confirm: t`Confirm`,
            }}
            open={isConfirmationResetOpen}
          />
        </div>
      </div>
      <div className="mt-4 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow">
        <div className="px-4 py-5 sm:px-6">
          <Typography variant="header-section">
            <Trans>Email Settings</Trans>
          </Typography>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <EmailSettingsForm
            defaultValues={{
              supportEmail: supportEmail ?? '',
            }}
            onSave={onUpdateEmailSettings}
          />
        </div>
      </div>
      <div className="mt-4 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow">
        <div className="px-4 py-5 sm:px-6">
          <Typography variant="header-section">
            <Trans>Support Phone Number Settings</Trans>
          </Typography>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <SupportPhoneNumberSettingsForm
            defaultValues={{
              supportPhoneNumber: supportPhoneNumber,
            }}
            onSave={onUpdateSupportPhoneNumberSettings}
          />
        </div>
      </div>
      <div className="mt-4 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow">
        <div className="px-4 py-5 sm:px-6">
          <Typography variant="header-section">
            <Trans>Save Payment Methods</Trans>
          </Typography>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <SavePaymentMethodForm
            defaultValues={{
              savePaymentMethod: savePaymentMethod as SavePaymentMethod,
            }}
            onSave={onUpdateSavePaymentMethod}
          />
        </div>
      </div>
    </div>
  );
};
