import {t} from '@lingui/macro';
import {InputSearchSelect} from '@zentact/ui-tailwind';

const PLUS_CIRCLE_SVG = `
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" 
     stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" 
        d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>
`;

export const NEW_CUSTOMER_EMAIL = 'new';

type Options = {
  id: string;
  label: string;
};

type Props = {
  merchantAccountId?: string;
  options: Options[];
  value?: string;
  onChange: (value: string) => void;
  disabled: boolean;
};

export const SelectOrAddNewEmail = ({value, options, onChange, disabled}: Props) => {
  return (
    <>
      <InputSearchSelect
        value={value}
        options={[
          {
            id: NEW_CUSTOMER_EMAIL,
            label: t`Add new customer`,
            iconUrl: t`data:image/svg+xml;charset=UTF-8,${encodeURIComponent(PLUS_CIRCLE_SVG)}`,
          },
          ...options,
        ]}
        label=""
        className="w-full mt-0"
        labelClassName="gap-0"
        onChange={value => onChange(value as string)}
        placeholder={t`Select customer...`}
        disabled={disabled}
        inSidePanel
      />
    </>
  );
};
