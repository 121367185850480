import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {CurrencyCode, ErrorCode, formatUrlQueryParams, isFormattedTrpcError} from '@zentact/common';
import {
  Breadcrumbs,
  Button,
  CheckoutIFrame,
  DeleteCustomerConfirmDialog,
  DropDownMinimalMenuIcon,
  Loading,
  PreauthorizeWithPaymentMethodDialog,
  SavedPaymentMethodCustomerDetails,
  ShopperSavedPaymentMethodsList,
  Typography,
  useNotification,
  useRequiredParams,
  useToggleWithData,
} from '@zentact/ui-tailwind';
import {useCallback, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

const getBreadCrumbs = (holderName: string) => [
  {name: t`Payments`, href: RoutePath.TAKE_PAYMENT, current: false},
  {name: t`Saved Payment Methods`, href: RoutePath.SAVED_PAYMENT_METHODS, current: false},
  {name: holderName, href: '#', current: true},
];

export const SavedPaymentMethodDetails = () => {
  const navigate = useNavigate();
  const {tenant, locale, currency} = useStore();
  const [deleteCustomerConfirmationOpen, setDeleteCustomerConfirmationOpen] = useState(false);
  const [checkoutUrlState, setCheckoutUrl] = useState('');
  const {showSuccessNotification} = useNotification();

  const {minAmount = 100, maxAmount = 100000} = tenant?.checkoutConfiguration || {};

  const checkoutMutation = trpc.checkout.startCheckout.useMutation();

  const {merchantAccountId, merchantShopperId} = useRequiredParams<{
    merchantAccountId: string;
    merchantShopperId: string;
  }>();
  const shopperInfo = trpc.savedPaymentMethod.getShopperById.useQuery(
    {merchantAccountId, merchantShopperId},
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      onError: error => {
        const errorCode = isFormattedTrpcError(error)
          ? error.data.errorCode
          : ErrorCode.ERROR_GENERIC;
        navigate(RoutePath.ERROR, {state: {errorCode}});
      },
    }
  );

  const handleAddPaymentMethod = useCallback(() => {
    if (!currency) {
      console.error('Currency is required to add a payment method');
      return;
    }
    if (!shopperInfo.data) {
      console.error('Shppper data is empty');
      return;
    }
    checkoutMutation.mutate(
      {
        amount: 0,
        shopper: {
          id: merchantShopperId,
          ...(shopperInfo.data.email && {email: shopperInfo.data.email}),
        },
        currency: currency as CurrencyCode,
        merchantAccountId: shopperInfo.data.merchantAccount.id,
        savePaymentMethod: 'addEmbed',
      },
      {
        onSuccess: ({checkoutUrl}) => {
          setCheckoutUrl(checkoutUrl);
        },
      }
    );
  }, [currency, merchantShopperId, shopperInfo.data]);

  const menuItems = useMemo(() => {
    return [
      {
        name: t`Add New Payment Method`,
        onClick: handleAddPaymentMethod,
      },
      {
        name: t`View Transactions`,
        onClick: () =>
          navigate(`${RoutePath.PAYMENTS}?${formatUrlQueryParams({shopperId: merchantShopperId})}`),
      },
      {
        name: t`Delete Customer`,
        onClick: () => setDeleteCustomerConfirmationOpen(true),
        itemClassName: 'text-red-500',
      },
    ];
  }, [merchantShopperId, handleAddPaymentMethod]);

  const {
    data: paymentMethodIdToken,
    on: openPreauthorizeDialog,
    off: closePreauthorizeDialog,
  } = useToggleWithData<string>();

  if (shopperInfo.isLoading || !shopperInfo.data) {
    return (
      <div className="flex items-center justify-center h-80">
        <Loading mode="inline" />
      </div>
    );
  }

  return (
    <div className="">
      <Breadcrumbs pages={getBreadCrumbs(shopperInfo.data?.name ?? '')} />
      <div className="flex items-center justify-between my-4">
        <Typography variant="header-page" className="flex">
          <Trans>{shopperInfo.data?.name} Payment Methods</Trans>
        </Typography>
        <div className="flex items-center justify-end">
          <DropDownMinimalMenuIcon items={menuItems} buttonContent={<Trans>Actions</Trans>} />
        </div>
      </div>
      <div className="py-5 mb-5 bg-white rounded-xl md:col-span-2">
        <div className="px-4 pb-5 mb-5 font-medium leading-6 border-b border-gray-200 sm:px-5">
          <Trans>Customer Details</Trans>
        </div>
        <div className="px-4 sm:px-6">
          <SavedPaymentMethodCustomerDetails shopper={shopperInfo.data} />
        </div>
      </div>
      <div className="py-5 bg-white rounded-xl md:col-span-2 ">
        <div className="flex items-center justify-between px-4 pb-5 mb-5 font-medium leading-6 border-b border-gray-200 sm:px-5">
          <div>
            <Trans>Payment Methods</Trans>
          </div>
          <Button variant="primary" size="md" className="w-fit" onClick={handleAddPaymentMethod}>
            <Trans>Add New</Trans>
          </Button>
        </div>
        <div className="px-4 sm:px-6">
          <ShopperSavedPaymentMethodsList
            shopperRow={shopperInfo.data}
            trpc={trpc}
            refetch={shopperInfo.refetch}
            openPreauthorizeDialog={openPreauthorizeDialog}
            locale={locale}
          />
        </div>
      </div>
      <DeleteCustomerConfirmDialog
        isOpen={deleteCustomerConfirmationOpen}
        onClose={() => {
          setDeleteCustomerConfirmationOpen(false);
        }}
        trpc={trpc}
        onSuccess={() => navigate(RoutePath.SAVED_PAYMENT_METHODS)}
        shopper={shopperInfo.data}
      />
      {!!paymentMethodIdToken && shopperInfo.data && (
        <PreauthorizeWithPaymentMethodDialog
          locale={locale}
          triggerRefetch={shopperInfo.refetch}
          trpc={trpc}
          shopper={shopperInfo.data}
          isOpen={!!paymentMethodIdToken}
          currency={currency}
          minAmount={minAmount}
          maxAmount={maxAmount}
          paymentMethodIdToken={paymentMethodIdToken}
          onClose={closePreauthorizeDialog}
        />
      )}
      {checkoutUrlState && (
        <CheckoutIFrame
          checkoutUrl={checkoutUrlState}
          expectedOrigin={tenant?.portalUrl}
          onClose={() => setCheckoutUrl('')}
          onSuccess={() => {
            shopperInfo.refetch();
            showSuccessNotification(t`Adding new payment method...`);
          }}
        />
      )}
    </div>
  );
};
