export const VisaMastercardDiscoverAmexIcon = () => {
  return (
    <svg
      width="107"
      height="60"
      viewBox="0 0 131 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Cards</title>
      <g clip-path="url(#clip0_7079_17736)">
        <path
          d="M26.0471 3.02344C23.2099 3.02344 20.6743 4.49569 20.6743 7.21517C20.6743 10.3342 25.1711 10.5496 25.1711 12.1168C25.1711 12.7766 24.4159 13.3672 23.1255 13.3672C21.2947 13.3672 19.9263 12.542 19.9263 12.542L19.3407 15.2867C19.3407 15.2867 20.9171 15.9838 23.0099 15.9838C26.1119 15.9838 28.5531 14.4395 28.5531 11.6732C28.5531 8.37711 24.0375 8.1681 24.0375 6.71387C24.0375 6.19696 24.6575 5.63081 25.9439 5.63081C27.3955 5.63081 28.5795 6.231 28.5795 6.231L29.1527 3.58039C29.1527 3.58039 27.8639 3.02344 26.0467 3.02344H26.0471ZM0.0883313 3.22363L0.0195312 3.62363C0.0195312 3.62363 1.21313 3.84224 2.28833 4.27867C3.67273 4.77877 3.77113 5.07025 4.00433 5.97434L6.54473 15.7772H9.95033L15.1967 3.22363H11.7991L8.42793 11.7588L7.05233 4.52371C6.92633 3.6957 6.28713 3.22323 5.50513 3.22323H0.0887313L0.0883313 3.22363ZM16.5631 3.22363L13.8979 15.7772H17.1379L19.7935 3.22363H16.5631ZM34.6335 3.22363C33.8523 3.22363 33.4383 3.64245 33.1347 4.37397L28.3879 15.7772H31.7855L32.4427 13.8765H36.5819L36.9815 15.7772H39.9795L37.3643 3.22363H34.6335ZM35.0755 6.61537L36.0827 11.326H33.3847L35.0759 6.61537H35.0755Z"
          fill="#1434CB"
        />
      </g>
      <path d="M58.8906 2.03125H68.1139V16.9671H58.8906V2.03125Z" fill="#FF5F00" />
      <path
        d="M59.8403 9.49682C59.8392 8.05864 60.168 6.63904 60.8019 5.34545C61.4359 4.05187 62.3582 2.9182 63.4993 2.03024C62.086 0.929036 60.3888 0.244265 58.6016 0.0541852C56.8144 -0.135895 55.0093 0.176385 53.3926 0.955334C51.7759 1.73428 50.4129 2.94847 49.4593 4.45914C48.5057 5.9698 48 7.71598 48 9.49812C48 11.2803 48.5057 13.0264 49.4593 14.5371C50.4129 16.0478 51.7759 17.262 53.3926 18.0409C55.0093 18.8199 56.8144 19.1321 58.6016 18.9421C60.3888 18.752 62.086 18.0672 63.4993 16.966C62.3579 16.0778 61.4353 14.9437 60.8014 13.6496C60.1674 12.3555 59.8388 10.9354 59.8403 9.49678V9.49682Z"
        fill="#EB001B"
      />
      <path
        d="M79.0007 9.50058C79.0007 11.2829 78.4949 13.0292 77.5411 14.5399C76.5873 16.0506 75.2241 17.2648 73.6072 18.0436C71.9903 18.8224 70.185 19.1345 68.3977 18.9441C66.6104 18.7538 64.9132 18.0687 63.5 16.9671C64.6406 16.0784 65.5627 14.9444 66.1968 13.6507C66.8309 12.3571 67.1604 10.9376 67.1604 9.49924C67.1604 8.06092 66.8309 6.64139 66.1968 5.34774C65.5627 4.05409 64.6406 2.92011 63.5 2.03136C64.9132 0.929804 66.6104 0.244692 68.3977 0.0543351C70.1851 -0.136022 71.9903 0.176057 73.6072 0.954899C75.2241 1.73374 76.5874 2.94792 77.5411 4.45864C78.4949 5.96936 79.0007 7.71567 79.0007 9.49794V9.50058Z"
        fill="#F79E1B"
      />
      <g clip-path="url(#clip1_7079_17736)">
        <path
          d="M96.4981 18.9961C101.744 18.9961 105.996 14.7437 105.996 9.49806C105.996 4.25243 101.744 0 96.4981 0C91.2524 0 87 4.25243 87 9.49806C87 14.7437 91.2524 18.9961 96.4981 18.9961Z"
          fill="url(#paint0_linear_7079_17736)"
        />
        <path
          opacity="0.65"
          d="M96.4981 18.9961C101.744 18.9961 105.996 14.7442 105.996 9.49806C105.996 4.25188 101.744 0 96.4981 0C91.2519 0 87 4.25188 87 9.49806C87 14.7442 91.2519 18.9961 96.4981 18.9961Z"
          fill="url(#paint1_radial_7079_17736)"
        />
      </g>
      <g clip-path="url(#clip2_7079_17736)">
        <path d="M114 18H131V1H114V18Z" fill="#006FCF" />
        <path
          d="M120.16 15.3139L126.882 15.3281L127.938 14.1504L129.001 15.3237H131.002V13.6053L129.749 12.254L131.002 10.9148V9.23223L120.16 9.22249V15.3139Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M120.883 14.6406V9.88741H124.913V10.9824H122.188V11.7256H124.848V12.8024H122.188V13.5325H124.913V14.6406L120.883 14.6406Z"
          fill="#006FCF"
        />
        <path
          d="M129.35 9.88747L127.983 11.394L126.621 9.88747H124.895L127.125 12.2613L124.895 14.6406H126.574L127.946 13.119L129.333 14.6406H131.002V14.5731L128.819 12.2613L131.002 9.92492V9.88747H129.35Z"
          fill="#006FCF"
        />
        <path
          d="M128.537 3.81486L127.979 5.37646L127.422 3.81486H124.196V5.89913L123.278 3.81486H120.665L117.98 9.90625H131.001V3.81486H128.537Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M121.341 7.20972L121.974 5.69788L122.606 7.20972H121.341ZM121.16 4.47738L119.051 9.22656H120.497L120.895 8.27559H123.051L123.449 9.22656H124.931L122.831 4.47738H121.16Z"
          fill="#006FCF"
        />
        <path
          d="M124.91 9.22328L126.189 9.2233V5.96109L127.402 9.2233H128.552L129.74 5.97217V9.23438L131 9.2233V4.47414H129.043L127.99 7.40081L126.944 4.48114L124.91 4.47412V9.22328Z"
          fill="#006FCF"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_7079_17736"
          x1="101.371"
          y1="17.1272"
          x2="94.7901"
          y2="6.82262"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F9A020" />
          <stop offset="0.2041" stop-color="#F99D20" />
          <stop offset="0.4348" stop-color="#F79320" />
          <stop offset="0.6196" stop-color="#F68820" />
          <stop offset="0.7969" stop-color="#F57E20" />
          <stop offset="1" stop-color="#F47521" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_7079_17736"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(100.314 16.3846) rotate(-117.808) scale(16.7386 12.983)"
        >
          <stop stop-color="#FF932C" />
          <stop offset="0.5677" stop-color="#E86D20" />
          <stop offset="0.8604" stop-color="#E85718" stop-opacity="0.8653" />
          <stop offset="1" stop-color="#640000" />
        </radialGradient>
        <clipPath id="clip0_7079_17736">
          <rect width="40" height="13" fill="white" transform="translate(0 3)" />
        </clipPath>
        <clipPath id="clip1_7079_17736">
          <rect width="19" height="19" fill="white" transform="translate(87)" />
        </clipPath>
        <clipPath id="clip2_7079_17736">
          <rect width="17" height="17" fill="white" transform="translate(114 1)" />
        </clipPath>
      </defs>
    </svg>
  );
};
