import {t} from '@lingui/macro';
import {CurrencyCode, LocaleCode, formatAmount, fromMinorUnits} from '@zentact/common';
import * as z from 'zod';

export const getTakePaymentFormSchema = ({
  minAmount,
  maxAmount,
  locale,
  currency,
  requireL2DataInPortalPaymentFeature,
  savedPaymentMethodsEnabled,
  minTaxPercentage,
  maxTaxPercentage,
}: {
  minAmount: number;
  maxAmount: number;
  locale: LocaleCode;
  currency: CurrencyCode;
  requireL2DataInPortalPaymentFeature: boolean;
  savedPaymentMethodsEnabled: boolean;
  minTaxPercentage: number;
  maxTaxPercentage: number;
}) =>
  z
    .object({
      merchantAccountId: z
        .string({
          // biome-ignore lint/style/useNamingConvention: Zod uses snake case
          required_error: t`Please select a merchant account`,
        })
        .min(1, t`Please select a merchant account`),

      storeId: z
        .string({
          // biome-ignore lint/style/useNamingConvention: Zod uses snake case
          required_error: t`Please select a store`,
        })
        .min(1, t`Please select a store`),

      amount: z
        .number({
          // biome-ignore lint/style/useNamingConvention: Zod uses snake case
          invalid_type_error: t`Only numbers and decimals are allowed`,
        })
        .multipleOf(0.01, t`Amount must be a multiple of 0.01`)
        .positive()
        .min(
          fromMinorUnits(minAmount, currency),
          t`Amount must be greater than or equal to ${formatAmount(minAmount, locale, currency)}`
        )
        .max(
          fromMinorUnits(maxAmount, currency),
          t`Amount must be less than or equal to ${formatAmount(maxAmount, locale, currency)}`
        ),
      selectedCustomerEmail: z
        .string()
        .min(1, t`Please select a customer email`)
        .max(50, t`Email must contain at most 50 character(s)`)
        .optional(),
      email: z
        .string()
        .email(t`Invalid email address`)
        .max(50, t`Email must contain at most 50 character(s)`),
      customAttributes: z.record(
        z.string(),
        z.string().max(80, t`The field must contain at most 80 characters`).optional()
      ),
      saleType: z.enum(['retail', 'service']),
      taxPercentage: z
        .number({
          // biome-ignore lint/style/useNamingConvention: Zod uses snake case
          invalid_type_error: t`Only numbers and decimals are allowed`,
        })
        .max(maxTaxPercentage, t`Max Tax Percentage is ${maxTaxPercentage}%`),
      l2CustomerReference: z.string().optional(),
      allowSurcharge: z.boolean().optional(),
    })
    .superRefine((data, ctx) => {
      if (!requireL2DataInPortalPaymentFeature || data.saleType === 'service') {
        return z.NEVER;
      }
      // feature enabled and saleType is 'retail'

      if (!data.taxPercentage) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['taxPercentage'],
          message: t`Tax Percentage is required`,
        });
      } else {
        if (data.taxPercentage < minTaxPercentage) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['taxPercentage'],
            message: t`Min Tax Percentage is ${minTaxPercentage}%`,
          });
        }
      }

      if (!savedPaymentMethodsEnabled && !data.l2CustomerReference) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['l2CustomerReference'],
          message: t`Customer ID is required`,
        });
      }

      return z.NEVER;
    });

export type TakePaymentFormData = z.infer<ReturnType<typeof getTakePaymentFormSchema>>;
