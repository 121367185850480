import {trpc} from '@/api/trpcClient';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {Trans} from '@lingui/macro';
import {GetCheckoutSavedPaymentMethodsOutput} from '@zentact/api/src/trpc/routers/checkoutRouter';
import {capitalizeCustomAttribute, paymentMethodVariantTranslationMap} from '@zentact/common';
import {InputRadio, ValidationError, useToggleWithData} from '@zentact/ui-tailwind';
import {DeletePaymentMethodConfirmDialog} from '@zentact/ui-tailwind/layout/finance/saved-payment-methods/delete-payment-method-confirm-dialog';
import {UseFormReturn} from 'react-hook-form';
import {CheckoutV2FormData} from '../checkout-schema';

type SchemePaymentMethodFormProps = {
  form: UseFormReturn<CheckoutV2FormData>;
  savedPaymentMethods: NonNullable<GetCheckoutSavedPaymentMethodsOutput>;
  refetch: () => void;
};

export const SavedMethodsForm = ({
  savedPaymentMethods,
  form,
  refetch,
}: SchemePaymentMethodFormProps) => {
  const {
    data: publicFacingId,
    on: openDeleteMethodConfirmation,
    off: closeDeleteMethodConfirmation,
  } = useToggleWithData<string | null>();

  const {
    register,
    setValue,
    formState: {errors},
  } = form;

  return (
    <div id="customCard-container">
      <div>
        {savedPaymentMethods.map(savedPaymentMethod => (
          <div className="flex items-center justify-between mb-2">
            <InputRadio
              {...register('paymentMethod.savedPaymentMethod.publicFacingId')}
              value={savedPaymentMethod.publicFacingId}
              label={`${
                paymentMethodVariantTranslationMap.get(savedPaymentMethod.paymentMethod) ??
                capitalizeCustomAttribute(savedPaymentMethod.paymentMethod)
              } **${savedPaymentMethod.cardLastFour}`}
            />
            <div className="flex items-center">
              {savedPaymentMethod.expiryYear &&
                savedPaymentMethod.expiryMonth &&
                new Date(savedPaymentMethod.expiryYear, savedPaymentMethod.expiryMonth, 0) <
                  new Date() && <span className="mr-3 font-semibold text-red-600">Expired</span>}
              <button
                type="button"
                className="text-red-600 w-fit hover:text-red-500 focus:ring-red-500"
                onClick={() => openDeleteMethodConfirmation(savedPaymentMethod.publicFacingId)}
              >
                <XMarkIcon className="w-6 h-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        ))}
        <ValidationError
          isVisible={Boolean(errors.paymentMethod?.savedPaymentMethod?.publicFacingId)}
        >
          {errors.paymentMethod?.savedPaymentMethod?.publicFacingId?.message}
        </ValidationError>
      </div>
      <button
        type="button"
        className="text-primary-600 w-fit hover:text-primary-500 focus:ring-primary-500 mt-4"
        onClick={() => setValue('paymentMethod.type', 'scheme')}
      >
        <Trans>+ Add payment method</Trans>
      </button>
      <DeletePaymentMethodConfirmDialog
        isOpen={!!openDeleteMethodConfirmation}
        onClose={closeDeleteMethodConfirmation}
        trpc={trpc}
        refetch={refetch}
        publicFacingId={publicFacingId}
        isCheckout={true}
      />
    </div>
  );
};
